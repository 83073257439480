import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src1524589205/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Test Mode and Live Mode`}</h1>
    <h2>{`Test Mode`}</h2>
    <p>{`When you create your Fidel API account and start exploring the platform, you are doing so in test mode, which means in a test environment. In this mode you can only create and use test cards and mock transactions, as this environment is not connected to any live card networks.`}</p>
    <p>{`The test environment provides a simulation of the live environment, meaning you can carry out most basic actions (create, retrieve, etc.) on test data. However, as the test environment is not connected to the card networks, it does not account for all behaviours of the payment ecosystem, such as the type of terminals, the POS provider, card types, etc. It also does not simulate the merchant onboarding process (e.g., the lead time for onboarding and the troubleshooting process if a location cannot be identified).`}</p>
    <h2>{`Live Mode`}</h2>
    <p>{`After signing the contract with Fidel API and receiving the approval of your use case from the card networks, you can `}<strong parentName="p">{`go live`}</strong>{`. Going live with a program means that `}<strong parentName="p">{`live mode`}</strong>{` becomes available in your Fidel API account, in addition to the test mode. This live environment is connected to the card networks that have approved your use case, so you can start linking cards to your program and receiving actual, real-time transactions via Fidel API.`}</p>
    <h2>{`The Go-Live Process`}</h2>
    <p>{`Click on the `}<strong parentName="p">{`Upgrade to go live`}</strong>{` button in the bottom left corner to access the live environment. The launch process will request business details, use case description and a card for billing purposes. The go-live request will be sent to Fidel API for review and approval.`}</p>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/upgrade-to-go-live-button.png",
        "alt": null
      }}></img></p>
    <h2>{`Switching Between Test and Live Mode`}</h2>
    <p>{`After the approval of your request, you can switch between the test and live environments by using the `}<strong parentName="p">{`Live mode`}</strong>{` toggle in the bottom left corner on the Fidel API dashboard.`}</p>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/live-mode-toggle.png",
        "alt": null
      }}></img></p>
    <p>{`In your code, using the live environment means using the live API and SDK keys instead of the test keys. You get access to your live API and SDK keys when going live. You can find your keys on the `}<strong parentName="p">{`Account settings`}</strong>{` page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      